import {
  ContactButton,
  ContentWrapper,
  ConveyorBeltGif,
  DesktopContent,
  HeaderText,
  LeftContentWrapper,
  LeftSide,
  MainContainer,
  MobileButtonContainer,
  MobileContent,
  MobileGifContainer,
  MobileMottoContainer,
  MottoContainer,
  Placeholder,
  RightSide,
} from "./styles";
import useWindowDimensions from "../../utils/windowSize";
import Google from "../../assets/images/Google.png";
import Apple from "../../assets/images/Apple.png";
import React, { useState } from "react";
import EmailSignUp from "./EmailSignUp";

export default function LandingPage() {
  const { width } = useWindowDimensions();
  const [isGifLoaded, setIsGifLoaded] = useState(false);

  const handleGifLoad = () => {
    setIsGifLoaded(true);
  };

  return (
    <MainContainer>
      {/* Desktop View */}
      <DesktopContent>
        <ContentWrapper>
          <LeftSide>
            <LeftContentWrapper>
              <MottoContainer>
                <HeaderText>Connecting the world</HeaderText>
                <HeaderText>to building information</HeaderText>
                <EmailSignUp />
              </MottoContainer>
            </LeftContentWrapper>
          </LeftSide>

          <RightSide>
            {!isGifLoaded && <Placeholder />}
            <ConveyorBeltGif
              src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2FLandingPage%2FTrimmedVid.mp4?alt=media&token=5421f279-0475-4fb6-9266-3ce90824118a"
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={handleGifLoad}
              style={{ display: isGifLoaded ? "block" : "none" }}
            />
          </RightSide>
        </ContentWrapper>
      </DesktopContent>

      {/* Mobile View */}
      <MobileContent>
        <MobileMottoContainer>
          <HeaderText>Connecting the World</HeaderText>
          <HeaderText>to Building Information</HeaderText>
        </MobileMottoContainer>

        <MobileGifContainer>
          {width && width > 414 ? (
            <ConveyorBeltGif
              src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2FLandingPage%2FTrimmedVid.mp4?alt=media&token=5421f279-0475-4fb6-9266-3ce90824118a"
              autoPlay
              loop
              muted
              playsInline
            />
            
          ) : (
            <>
            {!isGifLoaded && <Placeholder />}
            <ConveyorBeltGif
              src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2FLandingPage%2FTrimmed%20Mobile%20vid.mp4?alt=media&token=b6719d78-da17-4832-bdc0-f36809f596f4"
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={handleGifLoad}
              style={{ display: isGifLoaded ? "block" : "none" }}
            />
            </>
          )}

        </MobileGifContainer>
        <EmailSignUp />

        <MobileButtonContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
          >
            <img
              src={Apple}
              alt="download on app store"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
          >
            <img
              src={Google}  
              alt="download on play store"
            />
          </a>
        </MobileButtonContainer>
      </MobileContent>
    </MainContainer>
  );
}
